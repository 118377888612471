import React from 'react'
import PropTypes from 'prop-types'
import { useOverlayStore } from '../stores/overlay'

import Icon from '../svg/contact-footer.svg'

const FormLink = ({ linkLabel, form, type, page, icon, extraClasses, rate = null}) => {
  const {
    setOverlayDisplay,
    setContentType,
    setBody,
    setTitle,
    setRate,
  } = useOverlayStore()

  let classes

  switch (type) {
    case 'button':
      classes = `button text-center button-full relative inline-flex rounded-4 text-sm leading-none font-bold border border-solid border-blue-500 hover:bg-blue-100 text-blue-500  ${extraClasses}`
      break
    case 'buttonGreen':
      classes = `button text-center relative inline-flex rounded-4 text-sm leading-none font-bold border border-aqua-500 bg-aqua-500 hover:bg-opacity-80 text-white ${extraClasses}`
      break
    case 'buttonBooking':
      classes = `relative inline-flex items-center justify-center px-2 pt-4 pb-3 text-sm font-bold leading-none text-white border border-solid button rounded-4 bg-aqua-500 border-aqua-500 hover:border-opacity-80 hover:bg-opacity-80 focus:outline-aqua-500 md:w-full`
      break
    default:
      classes = `text-sm text-blue-500 transition transition-colors duration-300 hover:text-blue-400 ${extraClasses}`
      break
  }

  const handleClick = () => {
    setOverlayDisplay(true)
    setContentType('form')
    setBody(form)
    setTitle(page)
    setRate(rate)
  }

  if (icon) {
    return (
      <button
        type="button"
        className="p-1 mr-2 text-blue-500 border-2 border-blue-500 rounded-8"
        onClick={handleClick}
      >
        <Icon style={{ maxHeight: `24px` }} />
        <span className="sr-only">{linkLabel}</span>
      </button>
    )
  }
  return (
    <button
      type="button"
      className={classes}
      onClick={handleClick}
      data-text={linkLabel}
    >{type === 'buttonBooking' ?  
        <>{ linkLabel }</>
    : 
      <span>{linkLabel}</span>
    }
    </button>
  )
}

export default FormLink

FormLink.propTypes = {
  linkLabel: PropTypes.string,
  form: PropTypes.string,
  type: PropTypes.string,
  page: PropTypes.string,
}
